import { Timezone } from "@/interfaces/timezone";

export const Timezones: Timezone[] = [
  {
    id: 400,
    zone: "Alaska Standard Time"
  },
  {
    id: 399,
    zone: "Pacific Standard Time"
  },
  {
    id: 396,
    zone: "Mountain Standard Time"
  },
  {
    id: 389,
    zone: "Central Standard Time"
  },
  {
    id: 379,
    zone: "Eastern Standard Time"
  }
];
