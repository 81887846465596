import { EventBus } from "@/event-bus";
import { LimitConfig } from "@/interfaces/location";
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import Template from "./ValidateTextField.template.vue";

@Component({
  mixins: [Template]
})
export default class LimitCardToolTipComponent extends Vue {
  @Prop({ required: true })
  public limitConfig!: LimitConfig;
  @Prop({ required: true }) public quantityAmount!: number;
  @Prop({ required: false }) public dailyAmount!: boolean;
  @Prop({ required: true }) public index!: number;
  public errorMessage: boolean = false;
  public test: number = 1;
  @Prop({ required: false }) public disabled!: boolean;

  public validateInput(value: string, index: number) {
    if (value && !/^[0-9]{0,7}(?:\.[0-9]{1,3})?$/.test(value)) {
      this.test += 1;
      this.errorMessage = true;
      setTimeout(() => {
        this.errorMessage = false;
      }, 2500);
      this.$nextTick(() => {
        (this.$refs.quantity_input_ as HTMLElement).focus();
      });
    } else {
      this.errorMessage = false;
      if (this.dailyAmount) {
        this.limitConfig.configs[index].config.daily_amount = Number(value);
      } else {
        this.limitConfig.configs[index].config.amount = Number(value);
      }
    }
  }

  public validateInputCountables(value: string, index: number) {
    if (value && !/^[0-9]{0,7}?$/.test(value)) {
      this.test += 1;
      this.errorMessage = true;
      setTimeout(() => {
        this.errorMessage = false;
      }, 2500);
      this.$nextTick(() => {
        (this.$refs.quantity_input_ as HTMLElement).focus();
      });
    } else {
      this.errorMessage = false;
      if (this.dailyAmount) {
        this.limitConfig.configs[index].config.daily_amount = Number(value);
      } else {
        this.limitConfig.configs[index].config.amount = Number(value);
      }
    }
  }

  public validateCountableDecimal(
    value: KeyboardEvent,
    countableUnits: boolean
  ) {
    if (
      !/^[0-9]?\.?$/.test(value.key) ||
      (this.quantityAmount &&
        Number(value.key) === 0 &&
        Number(this.quantityAmount) === 0)
    ) {
      this.errorMessage = true;
      setTimeout(() => {
        this.errorMessage = false;
      }, 2500);
      if (Number(value.key) !== 0 && Number(this.quantityAmount) !== 0) {
        value.preventDefault();
      }
    }

    if (
      (countableUnits && value.key === ".") ||
      (this.quantityAmount &&
        Number(value.key) === 0 &&
        Number(this.quantityAmount) === 0)
    ) {
      this.errorMessage = true;
      setTimeout(() => {
        this.errorMessage = false;
      }, 2500);
      if (Number(value.key) !== 0 && Number(this.quantityAmount) !== 0) {
        value.preventDefault();
      }
    }
  }
  public mounted() {
    EventBus.$on("onChangeSalesLimit", () => {
      this.$validator.reset();
    });
    EventBus.$on("onUpdatingSalesLimit", async () => {
      const isValid = await this.$validator.validate();
      if (!isValid) {
        this.errorMessage = true;
        setTimeout(() => {
          this.errorMessage = false;
        }, 2500);
      }
    });
  }
}
