import LimitCardComponent from "@/components/locations/salesLimit/limitCard/LimitCard.component";
import { policyList } from "@/enums/permissions";
import { BatchType } from "@/interfaces/batchType";
import { LimitConfig, Rule } from "@/interfaces/location";
import { EventBus } from "@/internal";
import { Callback } from "@/types/types";
import { BooleanCheck } from "helix-vue-components";
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { Getter } from "vuex-class";
import UnitCardComponent from "../unitCard/UnitCard.component";
import Template from "./SalesLimitContent.template.vue";

@Component({
  mixins: [Template],
  components: {
    LimitCardComponent,
    UnitCardComponent
  }
})
export default class SalesLimitContentComponent extends Vue {
  @Prop({ required: true }) public limitConfig!: LimitConfig;
  @Prop({ required: true }) public batchTypes!: BatchType[];
  @Prop({ required: true }) public onLimitConfigChange: any;
  @Prop({ required: true }) public onLimitExemptChange!: Callback;
  @Prop({ required: true }) public onConfigDeletion!: Callback;
  @Prop({ required: true }) public unassignBatchType!: Callback;
  @Prop({ required: true }) public onStrategyChange!: Callback;
  @Prop({ required: true }) public index!: number;
  @Getter("hasPermission", { namespace: "PermissionsModule" })
  public hasPermission!: BooleanCheck;
  @Prop({ required: false }) public isModifyAbleSalesLimit!: boolean;
  public salesLimitMethods: Array<{ value: string; text: string }> = [];
  public salesLimitActions: Array<{ value: string; text: string }> = [];
  public salesLimitTimeOptions: Array<{ value: string; text: string }> = [];
  public dailySalesLimitTimeOptions: Array<{
    value: string;
    text: string;
  }> = [];
  public monthlyLimitOptions: Array<{ value: string; text: string }> = [];
  public dailyLimitOptions: Array<{ value: string; text: string }> = [];
  public overallLimitOptions: Array<{ value: string; text: string }> = [];
  public model!: LimitConfig;
  public keyTest: number = 1;
  public validationTimeFrame: boolean = false;
  public policyList = policyList;

  public addrule(rule: Rule) {
    const newRute: any = {
      id: `remove${Date.now()}`,
      name: "",
      section: rule.section,
      config: {
        amount: null,
        measure: "g",
        rule_field: "",
        batch_types: []
      }
    };

    this.limitConfig.configs.push(newRute);
  }

  public onBatchTypeAdded(batchTypeSelected: string, index: number) {
    this.limitConfig.configs[index].config.batch_types!.push(batchTypeSelected);
    this.onLimitConfigChange(
      this.index,
      this.limitConfig.configs,
      batchTypeSelected,
      "ADD"
    );
  }
  public onBatchTypeExempted(payload: { id: number; operation: string }) {
    this.onLimitExemptChange(this.index, payload);
  }

  public onBatchTypeRemoved(typeId: string, index: number) {
    this.limitConfig.configs[index].config.batch_types!.splice(
      this.limitConfig.configs[index].config.batch_types!.indexOf(typeId),
      1
    );
    const valueIndex = this.batchTypes.map(e => e.id).indexOf(typeId);
    this.onLimitConfigChange(
      this.index,
      this.limitConfig.configs,
      typeId,
      "DEL"
    );
  }

  get availableBatchTypes(): BatchType[] {
    const inUse: string[] = [];
    const exemptedTypes =
      this.limitConfig.strategy_config.batch_types_exempted || [];
    this.limitConfig.configs.forEach(config => {
      const batch_types = config.config.batch_types;
      if (batch_types) {
        batch_types.forEach(id => {
          inUse.push(id);
        });
      }
    });
    exemptedTypes.forEach(id => {
      if (id) {
        inUse.push(String(id));
      }
    });

    const res = this.batchTypes.filter(
      (d: BatchType, i) => inUse.indexOf(d.id!) === -1
    );
    return res;
  }

  public validateInputTimeframe(e: string) {
    if (e && !/^[0-9]{0,4}?$/.test(e)) {
      this.keyTest += 1;
      this.validationTimeFrame = true;
      setTimeout(() => {
        this.validationTimeFrame = false;
      }, 2500);
      this.$nextTick(() => {
        (this.$refs.quantity_input_time as HTMLElement).focus();
      });
    } else {
      this.validationTimeFrame = false;
      this.limitConfig.strategy_config.quantity = Number(e);
    }
  }

  public validateTimeframeDecimal(e: KeyboardEvent) {
    if (!/^[0-9]?\.?$/.test(e.key) || e.key === ".") {
      this.validationTimeFrame = true;
      setTimeout(() => {
        this.validationTimeFrame = false;
      }, 2500);
      e.preventDefault();
    }
  }

  public mounted() {
    this.salesLimitMethods = [
      {
        value: "multiple_overall_limits",
        text: this.$tc("multiple_overall_limits")
      },
      {
        value: "one_overall_limit",
        text: this.$tc("single_overall_limits")
      },
      {
        value: "one_daily_limit_with_monthly_overall",
        text: this.$tc("one_daily_limit_with_monthly_overall")
      }
    ];
    this.salesLimitActions = [
      {
        value: "block",
        text: this.$tc("limit.action_block")
      },
      {
        value: "warning",
        text: this.$tc("limit.action_warning")
      }
    ];
    this.salesLimitTimeOptions = [
      {
        value: "day",
        text: this.$tc("day", 2)
      },
      {
        value: "month",
        text: this.$tc("month", 2)
      },
      {
        value: "transaction",
        text: this.$tc("limit.transaction", 2)
      }
    ];
    this.dailySalesLimitTimeOptions = [
      {
        value: "day",
        text: this.$tc("day", 2)
      }
    ];
    this.monthlyLimitOptions = [
      {
        value: "rolling_monthly",
        text: this.$tc("limit.rolling_monthly")
      }
    ];
    this.dailyLimitOptions = [
      {
        value: "day",
        text: this.$tc("limit.per_day")
      }
    ];
    this.overallLimitOptions = [
      {
        value: "g",
        text: this.$tc("gram", 2)
      },
      {
        value: "oz",
        text: this.$tc("ounce", 2)
      }
    ];
    EventBus.$on("delete-rule", this.deleteRule);
  }

  public deleteRule(ruleId: number) {
    const configIndex = this.limitConfig.configs.map(c => c.id).indexOf(ruleId);
    if (configIndex !== -1) {
      this.onConfigDeletion(configIndex, this.index);
    }
  }
  public unassignBatchTypeBulk(data: { payload: object; index: number }) {
    this.unassignBatchType(data, this.index);
  }
}
