import { i18n } from "@/plugins/i18n";
import { number } from "mathjs";
import { State } from "./geographical";

export interface Location {
  account_id?: number;
  address1: string;
  address2: string;
  availability?: number;
  city: string;
  user_id?: number | null;
  created_at?: string;
  currency: string;
  date_format?: string;
  locale?: string;
  deleted_at?: string | null;
  deleted_by?: string | null;
  email?: string;
  id: number | null;
  price?: number;
  location_type: string;
  license_number: string;
  name: string;
  phone: string;
  country_id: number | null;
  state_id: number | null;
  timezone_id: number | null;
  timezone: string;
  updated_at?: string;
  website?: string;
  zip: string;
  location_id?: number;
  limit_time_span?: number;
  state?: State;
  full_address?: string;
  permission_locations?: boolean;
  dea?: string;
  license?: string;
}

export interface PaymentMethod {
  amount?: number | null;
  id?: number | null;
  name?: string | null;
  payment_method_id?: number;
  payment_method?: PaymentMethod;
  enabled?: number | null;
  type?:
    | "CASH"
    | "DEBIT_CARD"
    | "COUPON"
    | "CHECK"
    | "CREDIT_CARD"
    | "STORE_CREDIT";
}

export interface SetGroup {
  [key: string]: any;
}
// NUEVO LIMITS NO TOCAR NO BORRAR
export interface LocationSalesLimits {
  name: string;
  component: string;
  enabled: boolean;
  type: string;
  action: "block" | "warning";
  group: "medicinal" | "recreational" | null;
  strategy: string;
  configs: LocationSalesConfig[];
  strategy_config: {
    period: string;
    quantity: number | string;
    batch_types_exempted: Array<number | string | null>;
    batch_types_unassigned: Array<number | null>;
  };
}

export interface LocationSalesConfig {
  id?: number | string;
  shouldDelete?: boolean;
  delete?: boolean;
  name: string;
  limits_rule_id?: number;
  section?: "sales" | "units";
  config: {
    amount: number | null | string;
    measure: "g" | "mg" | "oz" | "unit" | "count";
    rule_field: string;
    batch_types?: string[];
    from: string;
    to: string;
    ext_limit_enabled: 0 | 1;
    type?: string;
    daily_amount?: number | null | string;
    daily_measure?: "g" | "mg" | "oz" | "unit" | "count";
    daily_ext_limit_enabled: 0 | 1;
  };
}
export interface LimitsComponents {
  name: string;
  component: string;
  type: string;
  group: "medicinal" | "recreational" | null;
  strategy: string;
}
export interface ConfigsSection {
  section?: "sales" | "units";
}
export type MeasureTypes = "g" | "mg" | "oz" | "unit" | "count";
export interface Rule {
  id?: number;
  delete?: boolean;
  name: string;
  limits_rule_id?: number;
  section?: "sales" | "units";
  config: {
    amount: number;
    measure: MeasureTypes;
    rule_field: string;
    batch_types: number[];
  };
}
export interface TimeRule {
  hour: string | Date;
  min: string | Date;
  range: string;
}

export const timeRule: TimeRule = {
  hour: "0",
  min: "0",
  range: "AM"
};

export interface LocationLimits {
  action: "block" | "warning";
  age: string;
  sale_hour: string;
}
export interface LimitConfig {
  id: number;
  enabled: boolean;
  delete?: boolean;
  group?: "medicinal" | "recreational" | null;
  type?: string;
  strategy: string;
  strategy_config: {
    period: string;
    quantity: number;
    batch_types_exempted: Array<number | null>;
    batch_types_unassigned: Array<number | null>;
    monthly_limit: string;
    daily_limit: string;
  };
  action: "block" | "warning";
  related_entity: string;
  related_entity_id: string;
  configs: LocationSalesConfig[];
  batch_types_exempted: number[];
  batch_types_unassigned: number[];
}

export const singleOverAllConfigObj: LocationSalesConfig = {
  id: `remove${Date.now()}`,
  shouldDelete: false,
  delete: false,
  name: i18n.t("single_overall_limits").toString(),
  limits_rule_id: undefined,
  section: "sales",
  config: {
    rule_field: "usable_weight",
    batch_types: [],
    amount: 70.87,
    measure: "g",
    from: "",
    to: "",
    ext_limit_enabled: 0,
    daily_ext_limit_enabled: 0
  }
};

export const oneWithMonthlyConfigObj: LocationSalesConfig = {
  id: `remove${Date.now()}`,
  shouldDelete: false,
  delete: false,
  name: i18n.t("one_daily_limit_with_monthly_overall").toString(),
  limits_rule_id: undefined,
  section: "sales",
  config: {
    rule_field: "usable_weight",
    batch_types: [],
    amount: "",
    measure: "g",
    from: "",
    to: "",
    daily_amount: "",
    daily_measure: "g",
    ext_limit_enabled: 0,
    daily_ext_limit_enabled: 0
  }
};
