import ValidateTextField from "@/components/locations/salesLimit/limitCard/ValidateTextField.component";
import { policyList } from "@/enums/permissions";
import { BatchType } from "@/interfaces/batchType";
import { ConfigsSection, LimitConfig, Location } from "@/interfaces/location";
import { EventBus } from "@/internal";
import { Callback } from "@/types/types";
import { BooleanCheck } from "helix-vue-components";
import { Component, Prop, Vue } from "vue-property-decorator";
import { Getter } from "vuex-class";
import Template from "./UnitCard.template.vue";

interface Options {
  value: string;
  text: string;
}
@Component({
  mixins: [Template],
  components: {
    ValidateTextField
  }
})
export default class UnitCardComponent extends Vue {
  @Prop({ required: true })
  public limitConfig!: LimitConfig;
  @Prop({ required: true })
  public configsSection!: ConfigsSection;
  @Prop({ required: true })
  public type!: string;
  @Prop({ required: true }) public batchTypes!: BatchType[];
  @Prop({ required: true }) public unassignBatchType!: Callback;
  @Prop({ required: true }) public availableBatchTypes!: BatchType[];
  @Prop({ required: true }) public onBatchTypeAdded: any;
  @Prop({ required: true }) public onBatchTypeRemoved: any;
  @Getter("hasPermission", { namespace: "PermissionsModule" })
  public hasPermission!: BooleanCheck;
  @Prop({ required: false }) public isModifyAbleSalesLimit!: boolean;
  @Getter("currentLocation", { namespace: "AuthModule" })
  public currentLocation!: Location;
  public batchTypeSelected: Array<null | number> = [];
  public ruleFields: Options[] = [];
  public ruleUnits: Options[] = [];
  public countableUnits: Options[] = [];
  public policyList = policyList;

  public salesLimitsCards() {
    if (this.configsSection === "sales") {
      return this.limitConfig.configs.filter(a => a.section === "sales");
    } else if (this.configsSection === "units") {
      return this.limitConfig.configs.filter(a => a.section === "units");
    } else {
      return [];
    }
  }

  public addProductType(batchTypeSelected: number, configId: number) {
    if (batchTypeSelected) {
      const index: number = this.limitConfig.configs
        .map(c => c.id)
        .indexOf(configId);
      this.limitConfig.configs[index].config.batch_types = this.limitConfig
        .configs[index].config.batch_types
        ? this.limitConfig.configs[index].config.batch_types
        : [];
      if (
        this.batchTypeSelected &&
        !this.limitConfig.configs[index].config.batch_types!.includes(
          // @ts-ignore
          batchTypeSelected
        )
      ) {
        this.onBatchTypeAdded(batchTypeSelected, index);
        this.batchTypeSelected[index] = null;
      }
    }
  }

  public deleteProductType(typeId: number, configId: number) {
    const index: number = this.limitConfig.configs
      .map(c => c.id)
      .indexOf(configId);
    this.updateRule({ id: typeId, isDelete: true });
    this.onBatchTypeRemoved(typeId, index);
  }

  public productTypeName(typeId: number) {
    const productType: BatchType | undefined = this.batchTypes.find(
      // @ts-ignore
      type => type.id === typeId
    );
    return productType ? productType.name : undefined;
  }

  public confirmDeletion(configId: number) {
    const isEmpty = configId || 0;
    const index = this.limitConfig.configs.map(c => c.id).indexOf(configId);
    if (isEmpty && typeof isEmpty !== "string") {
      this.limitConfig.configs[index].shouldDelete = true;
    } else {
      this.limitConfig.configs.forEach((elem, key) => {
        if (elem.id === configId) {
          elem.config.batch_types!.map(bt => {
            this.unassignBatchType({ id: bt, operation: "ADD" });
          });
          this.limitConfig.configs.splice(key, 1);
        }
      });
    }
  }

  public deleteCard(configId: number) {
    const index = this.limitConfig.configs.map(c => c.id).indexOf(configId);
    this.limitConfig.configs[index].delete = true;
    EventBus.$emit("delete-rule", configId);
  }

  public updateRule(batchType?: { id: number; isDelete: boolean }) {
    EventBus.$emit("onChangeSalesLimit");
    EventBus.$emit("update-rule", this.limitConfig, batchType);
  }

  public unitsChange(value: string, configId: number) {
    const index = this.limitConfig.configs.findIndex(i => i.id === configId);
    this.limitConfig.configs[index].config.amount = null;
    if (value === "units") {
      this.limitConfig.configs[
        index
      ].config.rule_field = this.countableUnits[0].value;
    } else {
      this.limitConfig.configs[
        index
      ].config.rule_field = this.countableUnits[1].value;
    }
    this.updateRule();
  }

  public mounted() {
    this.ruleFields = [
      {
        value: "usable_weight",
        text: this.$t("limit.usable_weight").toString()
      },
      {
        value: "package",
        text: this.$t("limit.package").toString()
      }
    ];
    this.ruleUnits = [
      {
        value: "oz",
        text: this.$t("limit.ounces").toString()
      },
      {
        value: "g",
        text: this.$t("limit.grams").toString()
      },
      {
        value: "mg",
        text: this.$t("limit.milligrams").toString()
      },
      {
        value: "units",
        text: this.$t("limit.units").toString()
      }
    ];
    this.countableUnits = [
      {
        value: "countable",
        text: this.$t("limit.countable").toString()
      },
      {
        value: "usable_weight",
        text: this.$t("limit.usable_weight").toString()
      }
    ];
  }
}
