import { render, staticRenderFns } from "./AgeLimit.template.vue?vue&type=template&id=6cb2c11c&scoped=true&"
var script = {}
import style0 from "./AgeLimit.template.vue?vue&type=style&index=0&id=6cb2c11c&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6cb2c11c",
  null
  
)

export default component.exports