import { LimitConfig } from "@/interfaces/location";
import { EventBus } from "@/internal";
import cloneDeep from "lodash/cloneDeep";
import { Component, Prop, Vue } from "vue-property-decorator";
import Template from "./AgeLimit.template.vue";

@Component({
  mixins: [Template]
})
export default class AgeLimitComponent extends Vue {
  @Prop({ required: true }) public limitConfig!: LimitConfig;
  @Prop({ required: false }) public isModifyAbleSalesLimit!: boolean;
  public model: LimitConfig | null = { ...this.limitConfig };
  public salesLimitActions: Array<{ value: string; text: string }> = [];
  public mounted() {
    this.setModel();
    this.salesLimitActions = [
      {
        value: "block",
        text: this.$tc("limit.action_block")
      },
      {
        value: "warning",
        text: this.$tc("limit.action_warning")
      }
    ];
    EventBus.$on("update-location-limits-model", this.setModel);
  }

  public setModel(limitConfig?: LimitConfig) {
    this.model = cloneDeep(limitConfig ? limitConfig : this.limitConfig);
  }

  public changeModel() {
    EventBus.$emit("change-sales-hour-limits", this.model);
  }
}
