import AgeLimitComponent from "@/components/locations/salesLimit/ageLimit/AgeLimit.component";
import SalesHourComponent from "@/components/locations/salesLimit/salesHour/SalesHour.component";
import SalesLimitContentComponent from "@/components/locations/salesLimit/salesLimitsContent/SalesLimitContent.component";
import { policyList } from "@/enums/permissions";
import { BatchType } from "@/interfaces/batchType";
import {
  LimitConfig,
  LimitsComponents,
  LocationSalesConfig,
  LocationSalesLimits,
  MeasureTypes,
  oneWithMonthlyConfigObj,
  singleOverAllConfigObj
} from "@/interfaces/location";
import { EventBus } from "@/internal";
import { i18n } from "@/plugins/i18n";
import { batchTypeService } from "@/services/batchType.service";
import { limitsService } from "@/services/limits.service";
import { BooleanCheck, Callback, PageNavAction } from "@/types/types";
import cloneDeep from "lodash/cloneDeep";
import { Component, Vue } from "vue-property-decorator";
import { Action, Getter, Mutation } from "vuex-class";
import Template from "./SalesLimit.template.vue";

const namespace = "AuthModule";

@Component({
  mixins: [Template],
  components: {
    SalesHourComponent,
    AgeLimitComponent,
    SalesLimitContentComponent
  }
})
export default class SalesLimitComponent extends Vue {
  @Action("setPageNav", { namespace: "PageNavModule" })
  public setPageNav!: PageNavAction;

  @Action("setLimits", { namespace })
  public limitsChange!: Callback;

  @Getter("limitConfig", { namespace })
  public limitConfig!: LocationSalesLimits[];
  @Getter("hasPermission", { namespace: "PermissionsModule" })
  public hasPermission!: BooleanCheck;

  public isModifyAbleSalesLimit!: boolean;
  public loading = false;
  public limitTypeToIndex: { [key: string]: any } = {};
  public stepValue: number | null = null;
  public locationSalesLimits: LocationSalesLimits[] = [];
  public batchTypes: BatchType[] = [];
  public enabledCheck = false;
  public deletedConfigs: LocationSalesConfig[] = [];
  public changedStrategyData: LocationSalesLimits[] = [];
  public batchCurrentPage!: number;
  public batchItemPerPage!: number;

  public limitsComponents: LimitsComponents[] = [
    {
      name: "sales_hour",
      component: "SalesHourComponent",
      type: "sale_hour",
      group: null,
      strategy: "quantity_between"
    },
    {
      name: "recreational_age_limit",
      component: "AgeLimitComponent",
      type: "age",
      group: "recreational",
      strategy: "quantity_between"
    },
    {
      name: "recreational_customer_sales_limits",
      component: "SalesLimitContentComponent",
      type: "cannabis_product" || "non_cannabis_product",
      group: "recreational",
      strategy: "multiple_overall_limits"
    },
    {
      name: "medical_age_limit",
      component: "AgeLimitComponent",
      type: "age",
      group: "medicinal",
      strategy: "quantity_between"
    },
    {
      name: "medical_customer_sales_limits",
      component: "SalesLimitContentComponent",
      type: "cannabis_product",
      group: "medicinal",
      strategy: "multiple_overall_limits"
    }
  ];

  @Mutation("setLimitConfig", { namespace })
  protected setLimits!: Callback;

  public async loadLimits() {
    this.loading = true;
    const locationSalesLimits = cloneDeep(this.limitConfig);
    this.limitTypeToIndex = {};
    locationSalesLimits.forEach((rule: LocationSalesLimits, i) => {
      if (
        rule.type === "non_cannabis_product" ||
        rule.type === "cannabis_product"
      ) {
        rule.configs.forEach(elem => {
          elem.delete = false;
          elem.shouldDelete = false;
        });
      }
      this.limitTypeToIndex[rule.type] = i;
    });
    locationSalesLimits.map(lsl => {
      if (lsl.type === "cannabis_product") {
        let assignedBatchIds: number[] = [];
        lsl.configs.map(config => {
          if (!config.config!.batch_types!) {
            config.config!.batch_types = [];
          }
          assignedBatchIds = [
            ...assignedBatchIds,
            Number(...config.config!.batch_types!)
          ];
        });
        if (!lsl.strategy_config!.batch_types_exempted) {
          lsl.strategy_config!.batch_types_exempted = [];
        }
        this.batchTypes.map(bt => {
          if (
            [
              ...assignedBatchIds,
              ...lsl.strategy_config!.batch_types_exempted!
            ].indexOf(Number(bt.id)) === -1
          ) {
            if (!lsl.strategy_config.batch_types_unassigned) {
              lsl.strategy_config.batch_types_unassigned = [];
            }
            if (
              lsl.strategy_config.batch_types_unassigned.indexOf(
                Number(bt.id)
              ) === -1
            ) {
              lsl.strategy_config.batch_types_unassigned.push(Number(bt.id));
            }
          }
        });
      }
    });
    this.loading = false;
    return locationSalesLimits;
  }
  public onConfigDeletion(configIndex: number, index: number) {
    this.locationSalesLimits[index].configs[
      configIndex
    ].config.batch_types!.map(bt => {
      this.unassignBatchType({ id: Number(bt), operation: "ADD" }, index);
    });
    const configId = this.locationSalesLimits[index].configs.splice(
      configIndex,
      1
    )[0].id;
    const deleteIndex = this.limitConfig[index].configs
      .map(c => c.id)
      .indexOf(configId);

    this.limitConfig[index].configs[deleteIndex].shouldDelete = true;
    this.limitConfig[index].configs[deleteIndex].delete = true;
    this.deletedConfigs.push(this.limitConfig[index].configs[deleteIndex]);
  }
  public onStrategyChange(value: string, index: number) {
    this.deletedConfigs = [];
    if (value === this.limitConfig[index].strategy) {
      this.locationSalesLimits = cloneDeep(this.limitConfig);
      this.locationSalesLimits[index].strategy_config.batch_types_exempted = [];
      this.locationSalesLimits[
        index
      ].strategy_config.batch_types_unassigned = [];
      this.batchTypes.map(batchType => {
        if (
          this.limitConfig[index].strategy_config.batch_types_exempted.includes(
            Number(batchType.id)
          )
        ) {
          this.locationSalesLimits[
            index
          ].strategy_config.batch_types_exempted.push(Number(batchType.id));
        } else if (
          this.limitConfig[
            index
          ].strategy_config.batch_types_unassigned.includes(
            Number(batchType.id)
          )
        ) {
          this.locationSalesLimits[
            index
          ].strategy_config.batch_types_unassigned.push(Number(batchType.id));
        }
      });
    } else {
      this.locationSalesLimits[index].strategy_config.batch_types_exempted = [];
      this.locationSalesLimits[
        index
      ].strategy_config.batch_types_unassigned = this.batchTypes.map(bt =>
        Number(bt.id)
      );

      if (this.locationSalesLimits[index].strategy === "one_overall_limit") {
        this.locationSalesLimits[index].configs = [];
        this.locationSalesLimits[index].configs.push(singleOverAllConfigObj);
      } else if (
        this.locationSalesLimits[index].strategy ===
        "one_daily_limit_with_monthly_overall"
      ) {
        this.locationSalesLimits[index].configs = [];
        this.locationSalesLimits[index].strategy_config.quantity = "";
        this.locationSalesLimits[index].configs.push(oneWithMonthlyConfigObj);
      } else {
        this.locationSalesLimits[index].configs = [];
      }
    }
  }
  public onLimitConfigChange(
    index: number,
    config: LocationSalesConfig[],
    batchId: number,
    operation: string
  ) {
    this.locationSalesLimits[index].configs = config;
    if (operation === "DEL") {
      this.unassignBatchType({ id: batchId, operation: "ADD" }, index);
    } else {
      this.assignBatchtype(batchId, index);
    }
  }
  public onLimitExemptChange(
    index: number,
    payload: { id: number | null; operation: string }
  ) {
    if (!this.locationSalesLimits[index].strategy_config.batch_types_exempted) {
      this.locationSalesLimits[index].strategy_config.batch_types_exempted = [];
    }
    if (payload.operation === "ADD") {
      this.exemptBatchtype(payload.id);
      if (
        this.locationSalesLimits[
          index
        ].strategy_config.batch_types_exempted.indexOf(payload.id) === -1
      ) {
        this.locationSalesLimits[
          index
        ].strategy_config.batch_types_exempted.push(payload.id);
      }
      this.locationSalesLimits[
        index
      ].strategy_config.batch_types_unassigned.splice(
        this.locationSalesLimits[
          index
        ].strategy_config.batch_types_unassigned!.indexOf(payload.id),
        1
      );
    } else {
      this.unassignBatchType({ id: payload.id, operation: "ADD" }, index);
      this.locationSalesLimits[
        index
      ].strategy_config.batch_types_exempted.splice(
        this.locationSalesLimits[
          index
        ].strategy_config.batch_types_exempted!.indexOf(payload.id),
        1
      );
    }
  }
  public unassignBatchType(
    payload: { id: number | null; operation: string },
    index: number
  ) {
    if (
      !this.locationSalesLimits[index].strategy_config.batch_types_unassigned
    ) {
      this.locationSalesLimits[
        index
      ].strategy_config.batch_types_unassigned = [];
    }

    if (payload.operation === "ADD") {
      if (
        this.locationSalesLimits[
          index
        ].strategy_config.batch_types_unassigned.indexOf(payload.id) === -1
      ) {
        this.locationSalesLimits[
          index
        ].strategy_config.batch_types_unassigned.push(payload.id);
      }
    }
  }
  public exemptBatchtype(batchId: number | null) {
    this.batchTypes = this.batchTypes.map(bt => {
      if (bt.id === batchId) {
        bt.sales_limit_exempt = true;
        bt.sales_limit_unassigned = false;
      }
      return bt;
    });
  }
  public assignBatchtype(batchId: number, index: number) {
    this.locationSalesLimits[
      index
    ].strategy_config.batch_types_unassigned.splice(
      this.locationSalesLimits[
        index
      ].strategy_config.batch_types_unassigned!.indexOf(batchId),
      1
    );
  }
  public currentConfigComponents() {
    return this.limitsComponents.filter(config =>
      this.locationSalesLimits.map(l => l.group).includes(config.group)
    );
  }
  public updateLimitConfig(
    field: string,
    measure: MeasureTypes,
    amount: number,
    index: number
  ) {
    if (field === "overall_limit") {
      this.locationSalesLimits[index].configs[0].config.measure = measure;
    } else {
      this.locationSalesLimits[index].configs[0].config.amount = amount;
    }
  }

  public updateConfirmation() {
    this.$modals
      .loadConfirmation(
        {
          title: i18n.t("limit.switch_limit").toString(),
          text: i18n.t("limit.limit_loss_warning").toString(),
          acceptButton: "yes",
          cancelButton: "no"
        },
        {
          size: "normal",
          positionX: "center",
          positionY: "top"
        }
      )
      .then(async response => {
        if (response) {
          this.updateLimits(this.changedStrategyData);
        }
      })
      .catch(error => {
        // Nothing to do
      });
  }

  public checkLimitAmountValue(
    strategyData: boolean,
    payload: LocationSalesLimits[],
    loopIndex: number
  ) {
    let amountError = true;
    payload[loopIndex].configs.map((limtis: LocationSalesConfig, index) => {
      if (
        !limtis.delete &&
        limtis.config.ext_limit_enabled &&
        (!limtis.config.amount || Number(limtis.config.amount) === 0)
      ) {
        EventBus.$emit("onUpdatingSalesLimit");
        amountError = false;
      } else if (
        !limtis.config.amount &&
        Number(limtis.config.amount) === 0 &&
        !limtis.config.ext_limit_enabled &&
        strategyData
      ) {
        limtis.config.amount = this.limitConfig[loopIndex].configs[
          index
        ].config.amount;
        amountError = true;
        EventBus.$emit("onChangeSalesLimit");
      } else if (
        !strategyData &&
        !limtis.delete &&
        !limtis.config.ext_limit_enabled &&
        (!limtis.config.amount || Number(limtis.config.amount) === 0)
      ) {
        amountError = false;
        EventBus.$emit("onUpdatingSalesLimit");
      }
    });
    return amountError;
  }

  public async triggerUpdate() {
    this.locationSalesLimits.map((lsl, index) => {
      if (lsl.type === "cannabis_product") {
        if (lsl.strategy === this.limitConfig[index].strategy) {
          const payload = cloneDeep(this.locationSalesLimits);
          payload[index].configs = [
            ...this.deletedConfigs,
            ...this.locationSalesLimits[index].configs
          ];
          const amountError = this.checkLimitAmountValue(true, payload, index);

          if (amountError) {
            this.updateLimits(payload);
          }
        } else {
          let amountError = true;
          const changedStrategyPayload = cloneDeep(this.locationSalesLimits);
          this.limitConfig.map((lc, limitConfigIndex) => {
            if (
              lc.type === "cannabis_product" &&
              lc.strategy !==
                this.locationSalesLimits[limitConfigIndex].strategy
            ) {
              const deletableConfigs = lc.configs.map(config => {
                config.shouldDelete = true;
                config.delete = true;
                return config;
              });
              changedStrategyPayload[limitConfigIndex].configs = [
                ...deletableConfigs,
                ...changedStrategyPayload[limitConfigIndex].configs
              ];

              amountError = this.checkLimitAmountValue(
                false,
                changedStrategyPayload,
                limitConfigIndex
              );
            }
          });

          if (amountError) {
            this.changedStrategyData = changedStrategyPayload;
            this.updateConfirmation();
          }
        }
      }
    });
  }

  public async updateLimits(payload: LocationSalesLimits[]) {
    this.loading = true;
    const newLimitConf = await limitsService.saveLimitsByLocation(payload);
    if (newLimitConf) {
      this.setLimits(newLimitConf);
    }
    this.loading = false;
    this.deletedConfigs = [];

    this.locationSalesLimits = await this.loadLimits();
  }
  public cancel() {
    this.$router.back();
  }

  public setTimeLocation(locationLimits: LimitConfig) {
    const index = this.locationSalesLimits.findIndex(
      lsl => lsl.type === locationLimits.type
    );
    this.locationSalesLimits[index].configs[0].config =
      locationLimits.configs[0].config;
    this.locationSalesLimits[index].action = locationLimits.action;
  }

  public async created() {
    const salesTypesPagination = await batchTypeService.getPagination();
    this.batchCurrentPage = salesTypesPagination.currentPage;
    this.batchItemPerPage = salesTypesPagination.itemsPerPage;
    EventBus.$on("change-sales-hour-limits", this.setTimeLocation);
    EventBus.$on("update-rules", this.onLimitConfigChange);
    await this.limitsChange();
  }

  public setEnabledCheck() {
    const index = this.locationSalesLimits.findIndex(
      lsl => lsl.type === "sale_hour"
    );
    this.locationSalesLimits[index].enabled = this.enabledCheck;
  }

  protected async mounted() {
    this.isModifyAbleSalesLimit = this.hasPermission(
      policyList.modifySalesLimits
    );

    this.setPageNav({
      title: "sales_limit",
      isLoading: () => this.loading,
      rightActions: {
        generalActions: () => [
          {
            icon: "fal fa-check",
            action: this.triggerUpdate,
            vuetifyProps: () => ({
              loading: this.loading,
              fab: true,
              small: true
            })
          },
          {
            icon: "fal fa-times",
            action: this.cancel,
            vuetifyProps: () => ({
              loading: this.loading,
              fab: true,
              small: true
            })
          }
        ]
      }
    });
    let batchTypesPagination = await batchTypeService.getPagination();
    batchTypesPagination.totalItems = Infinity;
    batchTypesPagination.currentPage = 0;
    while (this.batchTypes.length < batchTypesPagination.totalItems) {
      const batchTypes = await batchTypeService.get({
        per_page: Infinity,
        page: batchTypesPagination.currentPage + 1
      });
      batchTypesPagination = await batchTypeService.getPagination();
      this.batchTypes = this.batchTypes.concat(batchTypes);
    }
    const batchSalesTypes = await batchTypeService.get({
      per_page: this.batchItemPerPage,
      page: this.batchCurrentPage
    });

    this.locationSalesLimits = await this.loadLimits();

    const index = this.locationSalesLimits.findIndex(
      lsl => lsl.type === "sale_hour"
    );
    if (index >= 0) {
      this.enabledCheck = this.locationSalesLimits[index].enabled;
    }
    this.locationSalesLimits.map(lsl => {
      if (lsl.type === "cannabis_product") {
        if (lsl.strategy === "one_overall_limit" && lsl.configs.length === 0) {
          lsl.configs[0] = singleOverAllConfigObj;
        }
      }
    });
    this.setLimits(cloneDeep(this.locationSalesLimits));
  }
}
