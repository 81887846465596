import { pusherEvents } from "@/enums/pusherEvents";
import { LimitConfig, TimeRule, timeRule } from "@/interfaces/location";
import { Location } from "@/interfaces/location";
import { EventBus } from "@/internal";
import { Timezones } from "@/metadata/timezone";
import { localDateToUTCTime, UTCTimeToLocalDate } from "@/utils/date-fns.utils";
import cloneDeep from "lodash/cloneDeep";
import { Component, Prop, Vue } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import Template from "./SalesHour.template.vue";

@Component({
  mixins: [Template],
  inject: ["$changes"]
})
export default class SalesHourComponent extends Vue {
  @Getter("currentLocation", { namespace: "AuthModule" })
  public currentLocation!: Location;
  @Prop({ required: true }) public limitConfig!: LimitConfig;
  @Prop({ required: false }) public isModifyAbleSalesLimit!: boolean;
  @Action("reloadLocation", { namespace: "AuthModule" })
  public reloadLocation!: () => void;
  public salesLimitActions: Array<{ value: string; text: string }> = [];
  public timeAfter: TimeRule | Date = { ...timeRule };
  public timeBefore: TimeRule | Date = { ...timeRule };
  public mounted() {
    this.setModel();
    this.salesLimitActions = [
      {
        value: "block",
        text: this.$tc("limit.action_block")
      },
      {
        value: "warning",
        text: this.$tc("limit.action_warning")
      }
    ];
    EventBus.$on("update-location-limits-model", this.setModel);
    this.$changes.watch(pusherEvents.locationTouched, this.reloadLocation);
  }
  public get model() {
    return cloneDeep(this.limitConfig);
  }

  public setModel() {
    this.timeBefore = UTCTimeToLocalDate(
      this.limitConfig.configs[0].config.from
    );
    this.timeAfter = UTCTimeToLocalDate(this.limitConfig.configs[0].config.to);
  }
  public changeModel() {
    this.limitConfig.configs[0].config.from = localDateToUTCTime(this
      .timeBefore as Date);
    this.limitConfig.configs[0].config.to = localDateToUTCTime(this
      .timeAfter as Date);
    EventBus.$emit("change-sales-hour-limits", this.model);
  }

  public get timeZone() {
    const timezone = Timezones.find(
      obj => obj.id === this.currentLocation.timezone_id
    );
    return timezone!.zone;
  }
}
