var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sales-limit"},[_c('div',{staticClass:"limit"},[(_vm.locationSalesLimits)?_c('div',{staticClass:"limit__types"},[_c('v-expansion-panel',{model:{value:(_vm.stepValue),callback:function ($$v) {_vm.stepValue=$$v},expression:"stepValue"}},_vm._l((_vm.currentConfigComponents()),function(limit,i){return _c('v-expansion-panel-content',{key:i,attrs:{"hide-actions":""}},[_c('div',{attrs:{"slot":"header"},slot:"header"},[_c('v-layout',{attrs:{"align-center":"","justify-start":""}},[_c('v-btn',{attrs:{"icon":""}},[(_vm.stepValue === i)?_c('v-icon',{attrs:{"color":"#0091ff","size":"40"}},[_vm._v("expand_more")]):_c('v-icon',{attrs:{"color":"#7f7f7f","size":"40"}},[_vm._v("chevron_right")])],1),_vm._v(" "),_c('span',{staticClass:"limit__types__label"},[_vm._v(_vm._s(_vm.$t(limit.name)))]),_vm._v(" "),(limit.component === 'SalesHourComponent')?_c('span',{on:{"click":function($event){$event.stopPropagation();}}},[_c('v-checkbox',{staticClass:"checkbox",attrs:{"label":"Enable","color":"primary","disabled":!_vm.isModifyAbleSalesLimit,"hide-details":true},on:{"change":_vm.setEnabledCheck},model:{value:(_vm.enabledCheck),callback:function ($$v) {_vm.enabledCheck=$$v},expression:"enabledCheck"}})],1):_vm._e()],1)],1),_vm._v(" "),_c('div',[_c(limit.component,_vm._b({tag:"component"},'component',{
                batchTypes: _vm.batchTypes,
                limitConfig:
                  _vm.locationSalesLimits[_vm.limitTypeToIndex[limit.type]],
                index: _vm.limitTypeToIndex[limit.type],
                onLimitConfigChange: _vm.onLimitConfigChange,
                onLimitExemptChange: _vm.onLimitExemptChange,
                onStrategyChange: _vm.onStrategyChange,
                onConfigDeletion: _vm.onConfigDeletion,
                unassignBatchType: _vm.unassignBatchType,
                isModifyAbleSalesLimit: _vm.isModifyAbleSalesLimit
              },false))],1)])}),1)],1):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }